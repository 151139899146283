import React from 'react';
import styles from './terms.module.scss';

export function PrivacyPolicyContent() {
    return (
        <div className={styles.termsContent}>
            <h3>Introduction</h3>
            <p>Please, pay attention we don’t collect any personal identifying information. Any questions, comments or complaints that you might have should be emailed to legal@p1k.org.</p>
            <p>You may also contact us through email available on the Website.</p>

            <h3>License and Website Access</h3>
            <p>We grant you a limited license to access and make personal use of this Website and/or viewing the content published on this Website. You are not allowed to download or modify it. This may be done only with written consent from us.</p>

            <h3>Links to other Websites</h3>
            <p>Our Website may contain links to other websites. If you choose to click on a third party link, you will be directed to that third party’s website. The fact that we link to a website is not an endorsement, or authorization that third party, nor is it an endorsement of their privacy or information security policies or practices. We encourage you to read the privacy policies or statements of the other websites you visit.</p>

            <h3>Usage Statistic</h3>
            <p>We may collect Website usage information in order to improve function of our Website. This information will be only used in an anonymized fashion, and never in association with your name, email, or other personally identifying information.</p>
            <p>When You contact us via email, we may incidentally collect your personal information, but we will keep that information strictly secure and confidential and will use solely for the purpose of assistance with your request.</p>

            <h3>Changes and Updates</h3>
            <p>This Privacy Policy may be revised periodically, and this will be reflected by the “Last update posted” date above. Please revisit this page to stay aware of any changes.</p>

            <h3>Contacting Us</h3>
            <p>If You have any questions about this Privacy Policy, please contact Us by email address <a href="mailto:letstalk@p1k.org">letstalk@p1k.org</a>.</p>
        </div>
    );
}
