import React from 'react';

import lax from 'lax.js';

export function useLax() {
    const requestRef = React.useRef(-1);

    React.useEffect(() => {
        lax.setup({ breakpoints: { xs: 0, md: 768, lg: 1300 } });
        const elements = document.querySelectorAll<HTMLElement>('[data-lax]');
        elements.forEach(lax.addElement);

        const updateLax = () => {
            lax.update(window.scrollY);
            requestRef.current = window.requestAnimationFrame(updateLax);
        };
        requestRef.current = window.requestAnimationFrame(updateLax);

        const onResize = () => lax.updateElements();
        window.addEventListener('resize', onResize, { passive: true });

        return () => {
            window.removeEventListener('resize', onResize);
            window.cancelAnimationFrame(requestRef.current);

            // Reset prev elements set
            lax.elements = [];
            elements.forEach((el: HTMLElement) => {
                el.style.transform = '';
            });
        };
    }, []);
}
