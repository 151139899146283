import React from 'react';
import styles from './terms.module.scss';

export function TermsOfUseContent() {
    return (
        <div className={styles.termsContent}>
            <p>These Terms of Use ("Terms", "Terms of Use"), governs your access and use of this Website p1k.org (hereinafter also referred to as “Website”), provided by Phase one Karma Ltd. (hereinafter also referred to as the “company”, “we”, “us”).</p>

            <p>These Terms will be applied fully and affect to your use of this Website. By using this Website, you agreed to accept all terms and conditions written in here. You must not use this Website if you disagree with any of this Terms of Use.</p>

            <h3>Intellectual Property Rights</h3>
            <p>You acknowledge and agree that all rights in and to the our Website, content published on this Website, including visual interface, digital downloads, images, text materials, graphics, logos and all other elements of the Website are and shall remain our sole property.</p>
            <p>Except as expressly stated herein, these Terms do not grant you any rights to, or in, patents, copyrights, trade secrets, trade names, trademarks (whether registered or unregistered), or any other rights or licenses in respect of the Website.</p>
            <p>You agree not to copy, imitate, or use our logo without our prior written consent.</p>

            <h3>License and Website Access</h3>
            <p>We grant you a limited license to access and make personal use of this Website and/or viewing the content published on this Website. You are not allowed to download or modify it. This may be done only with written consent from us.</p>

            <h3>Restrictions</h3>
            <p>
                You are specifically restricted from all of the following:
                <ul>
                    <li>copy, modify duplicate or create derivative works based on the Website;</li>
                    <li>republish, download, display, transmit, or distribute all or any portion of the Website or content published on this Website in any form or media or by any means;</li>
                    <li>selling, sublicensing and/or otherwise commercializing all or any portion of the Website or content published on this Website;</li>
                    <li>publicly performing and/or showing all or any portion of the Website or content published on this Website;</li>
                    <li>using this Website in any way that is or may be damaging to this Website;</li>
                    <li>using this Website in any way that impacts user access to this Website;</li>
                    <li>using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;</li>
                </ul>
            </p>


            <h3>No Warranties</h3>
            <p>This Website is provided “as is,” with all faults, and we express no representations or warranties, of any kind related to this Website or content published on this Website.</p>

            <h3>Limitation of liability</h3>
            <p>In no event the company shall nor any of its officers, directors, employees and contactors shall be held liable for anything arising out of or in any way connected with your use of this Website whether such liability is under contract.  The Company, including its officers, directors, employees and contractors shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.</p>

            <h3>Indemnification</h3>
            <p>You hereby indemnify to the fullest extent the company from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms.</p>

            <h3>Applicable Law</h3>
            <p>By visiting this Website, you agree that the laws of the England will govern these Terms of Use, or any dispute of any sort that might come between us and you.</p>

            <h3>Disputes</h3>
            <p>All disputes related to these Terms of Use shall be resolved by negotiations between us and you, and we shall make all efforts to resolve the dispute. If the we fail to reach consent, the disputes shall be resolved by arbitration under the Arbitration Institute of the Stockholm Chamber of Commerce in accordance with its Arbitration Rules</p>

            <h3>Modification of this Terms of Use.</h3>
            <p>We reserve the right to change these Terms of Use. We may notify You about such changes in this Terms of Use, through notification on the Website.The updates become legally binding after the posting of the renewed Terms on the Website.</p>

            <h2>CONTACT US:</h2>
            <p>Contact email: <a href="mailto:letstalk@p1k.org">letstalk@p1k.org</a></p>
        </div>
    );
}
