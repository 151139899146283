import React from 'react';
import styles from './terms.module.scss';

type TermsHeadingProps = {
    title: string;
    effectiveDate: string;
    titleTag?: 'h1' | 'h2' | 'h3';
    [key: string]: any;
};

export function TermsHeading(props: TermsHeadingProps): JSX.Element {
    const { title, effectiveDate, titleTag = 'h1', ...elseProps } = props;

    return <div className={styles.termsHeading} {...elseProps}>
        {React.createElement(titleTag, {
            className: styles.termsHeading__title,
        }, title)}

        <p className={styles.termsHeading__date}>Effective date {effectiveDate}</p>
    </div>;
}
