import React from 'react';
import { Grid } from 'components/grid/grid';
import styles from './terms.module.scss';

export function TermsRoot(props: any) {
    const { children, ...elseProps } = props;

    return (
        <Grid className={styles.termsRoot} {...elseProps}>
            {children}
        </Grid>
    );
}
