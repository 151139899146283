import React from 'react';
import { Helmet } from 'react-helmet';
import { useLax } from 'utils/hooks/lax';
import { TermsRoot, TermsBody, TermsHeading, PrivacyPolicyContent, TermsOfUseContent } from 'components/terms';

export default function TermsPage(): JSX.Element {
    useLax();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Phase One Karma — Terms, Conditions and Privacy Policy</title>
                <meta name="description"
                      content="Phase One Karma is a human-centered software product company focused on launching meaningful AI technologies and innovations." />
            </Helmet>

            <TermsRoot>
                <TermsBody id="terms-of-use">
                    <TermsHeading title="Terms of use" effectiveDate="May 29, 2020" />
                    <TermsOfUseContent />
                </TermsBody>

                <TermsBody id="privacy">
                    <TermsHeading title="Privacy Policy" effectiveDate="May 29, 2020" />
                    <PrivacyPolicyContent />
                </TermsBody>
            </TermsRoot>
        </>
    );
}
