import React from 'react';
import styles from './terms.module.scss';

export function TermsBody(props: any) {
    const { children, ...elseProps } = props;

    return (
        <section className={styles.termsBody} {...elseProps}>
            {children}
        </section>
    );
}
